// import {  RouteRecordRaw } from 'vue-router'
/**
 * 静态路由配置
 */
export const staticRoute: any = [
    {   //登录页
        path: '/',
        name: 'Login',
        component: ()=> import("@/views/login/index.vue")
    },
    {   //上传文件页面，暂时只有这两个页面
        //加其他页面采用 children 格式
        path: '/UploadFile',
        name: 'UploadFile',
        component: () => import( '@/views/uploadFile/index.vue')
    }
]