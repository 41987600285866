import { PersistedStateOptions } from "pinia-plugin-persistedstate";

/**
 * @description pinia持久化参数配置
 * @param {String} key 存储到持久化的 name
 * @param {Array} paths 需要持久化的 state name
 * @return persist
 * */
const piniaPersistConfig = (key: string, paths?: string[]) => {
	const persist: PersistedStateOptions = {
		key,  // 开启缓存  默认会存储在本地localstorage
		storage: window.localStorage, // 缓存使用方式
		// storage: window.sessionStorage,
		paths // 需要缓存键 
	};
	return persist;
};

export default piniaPersistConfig;
