import { createRouter, createWebHashHistory } from 'vue-router'
import { staticRoute } from './model/staticRoute'
import {GlobalStore} from '@/pinia';
import { storeToRefs } from 'pinia'
/**
 * 创建路由
 */
const router = createRouter({
  history: createWebHashHistory(),
  routes: [...staticRoute],
  strict: false
})
/**
 * 配置全局路由守卫
 * axios 响应拦截有写接口500 直接清除token,并跳转
 */
// router.beforeEach(async (to, from, next) => {
//   const globalStore = GlobalStore()
//   const { token } = storeToRefs(globalStore)
//   // // 判断是否有 Token，没有重定向到 login
//   if (!token) return next('/')
//   console.log("token", token)
//   next();
// })

export default router
