import { defineStore } from 'pinia'
import piniaPersistConfig from "@/config/piniaPersist";
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

// defineStore 调用后返回一个函数，调用该函数获得 Store 实体
export const GlobalStore = defineStore({
    // id: 必须的，在所有 Store 中唯一
    id: "GlobalState",
    // state: 返回对象的函数
    /**
     * 1.必须是函数：因为可以在服务器端渲染时避免交叉请求导致数据状态污染
     * 2.必须时箭头函数：为了更好的 TS 类型推导
     */
    state: (): any => ({
        userName: "",// 登录名称
        token: '',
        language: 'zh',// 语言
    }),
    // getters 类似于computed 用来封装计算属性，有缓存
    getters: {},
    // actions 类似于组建的methods 封装业务逻辑，修改state
    actions: {
        // 储存token
        setToken(token: string) {
            this.token = token;
        },
        // 储存用户名
        setUserName(name: any) {
            this.userName = name
        },
    },
    persist: piniaPersistConfig("GlobalState")
});

// piniaPersist(持久化)
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

export default pinia;